import {
  Flex,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Background1 from "../../images/Service1Background.svg";
import Donation1 from "../../images/donation-1.svg";
import Donation2 from "../../images/donation-2.svg";
import Donation3 from "../../images/donation-3.svg";
import Donation4 from "../../images/donation-4.svg";
import Footer from "../Footer/Footer";

const Donation = () => {
  const c1 = useBreakpointValue({
    base: "mob-1", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const c2 = useBreakpointValue({
    base: "mob-2", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        h={{ base: "40vh", md: "80vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDir={"column"}
      >
        <Flex
          width={{ base: "full", md: "80vw" }}
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          paddingBottom={"50px"}
        >
          <Text
            fontSize={{ base: "26px", md: "57px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"82px"}
            maxW={{ base: "100%", md: "50%" }}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            Hero text about Charity and why you should Do it
          </Text>
        </Flex>
      </Flex>
      <Image src={Donation1} />
      <Flex
        bgImage={`url('${Background1}')`}
        className={c2}
        padding={"45px"}
        paddingBottom={{ base: "75px", md: "45px" }}
        w={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={{ base: "center", md: "start" }}
        justifyContent={"center"}
        flexDir={"column"}
      >
        <Text
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "36px", md: "55px" }}
          fontWeight={900}
          lineHeight={{ base: "", md: "80px" }}
        >
          Orphans
        </Text>
        <Flex
          //   mx={{ base: "0", md: "36px" }}
          flexDir={{ base: "column", md: "row" }}
          alignItems={"start"}
          justifyContent={"start"}
        >
          <VStack gap={"20px"} alignItems={"start"}>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              The vision is to support. How? Starting by the basics-{" "}
              <span style={{ fontWeight: 700 }}>Food, Shelter, Clothes</span>,
              and <span style={{ fontWeight: 700 }}>Education</span>.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              Almost all Orphans have no one to support, they struggle even with
              the basics.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              So we will start with the basics. We are planning to expand it to
              the level where we have our own place where they all will live,
              eat, and study.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              All responsibility will be upon us till they turn the right age.
              This is where Kaliyuga ka Gurukul comes in.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              One of the sole purposes of Kaliyuga ka Gurukul is to educate
              those who need it the most.
            </Text>
          </VStack>
          <VStack gap={"20px"} alignItems={"start"}>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              With God’s grace, we are envisioning to have our place soon where
              we can give the utmost quality of life and education. The kind of
              life their parents would have thought of giving.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              There is no bigger misery than being an orphan. Period.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              No one to support you. No one to help you. No one to provide for
              you. No one to take care of you. No one to teach you how you
              should live your life. No one to help you with your purpose.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={400}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              The struggles are never-ending. And no one can truly feel the pain
              of the Orphans, a person can feel it when he/she visits the
              orphanage, but once they are back, they will be back to their
              normal lives.
            </Text>
            <Text
              fontSize={{ base: "15px", md: "22px" }}
              fontWeight={700}
              lineHeight={{ base: "16px", md: "30px" }}
              maxW={{ base: "full", md: "50vw" }}
            >
              One has to live it to feel it.
            </Text>
          </VStack>
        </Flex>
      </Flex>
      <Flex
        flexDir={{ base: "column-reverse", md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"36px"}
      >
        <VStack gap={"40px"} h={"full"} mx={{ base: "20px", md: "auto" }}>
          <Text
            fontSize={{ base: "15px", md: "26px" }}
            lineHeight={"36px"}
            fontWeight={400}
            maxW={{ base: "full", md: "45vw" }}
          >
            This Mission is closely attached to Jay for many reasons - one of
            the reasons is he lost his father in his teenage years and had to
            start early in his life to support his family. He doesn't like to
            talk about this, but here he is because these things are connected,
            and he is a firm believer of{" "}
            <span style={{ fontWeight: 700 }}>
              “Do what needs to be done, whether you like it or not”
            </span>
            . He knows it, because he has lived it all. And thus, his deepest
            desire is to provide and support as many orphans as possible because
            they do not need to go through the misery of life alone.
          </Text>
          <Text
            fontSize={{ base: "15px", md: "26px" }}
            lineHeight={"36px"}
            fontWeight={400}
            maxW={{ base: "full", md: "45vw" }}
          >
            They should know, there are people out there - who genuinely cares.
            You, me, Us are those people
          </Text>
        </VStack>
        <Image src={Donation2} />
      </Flex>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c2}
        padding={"70px"}
        paddingLeft={{ base: "20px", md: "70px" }}
        paddingRight={{ base: "20px", md: "70px" }}
        paddingBottom={{ base: "222px", md: "100px" }}
        w={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        backgroundPosition={{ base: "center", md: "top" }}
        alignItems={{ base: "center", md: "start" }}
        justifyContent={"center"}
        flexDir={"column"}
        gap={"36px"}
      >
        <Text
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "36px", md: "55px" }}
          fontWeight={900}
          lineHeight={{ base: "", md: "80px" }}
          w={"full"}
          textAlign={"center"}
          marginTop={{ base: "60px", md: "0px" }}
        >
          AChosenSon Charitable Trust
        </Text>
        <Flex
          flexDir={{ base: "column-reverse", md: "row" }}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"36px"}
        >
          <VStack
            gap={"40px"}
            h={"full"}
            mx={{ base: "20px", md: "auto" }}
            alignItems={"start"}
          >
            <Text
              w={"full"}
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "24px", md: "36px" }}
              lineHeight={"36px"}
              fontWeight={900}
              maxW={{ base: "full", md: "45vw" }}
              textAlign={{ base: "center", md: "start" }}
              textTransform={"capitalize"}
            >
              Old age People
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Jay’s vision extends to supporting elderly people who find
              themselves alone in the later stages of their lives.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Often neglected or forgotten by their own families, many of these
              elders have no place to call home and lack the love and care they
              deserve.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Jay dreams of providing them with more than just financial aid; he
              wants to offer them a place to live, warm meals to eat, clothes to
              wear, and most importantly, a sense of family that they have lost.
              He believes that no elderly person should have to spend their days
              feeling abandoned or unloved.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Through AChosenSon Charitable Trust, we aim to create a safe haven
              where they are valued, heard, and surrounded by compassion. Jay's
              commitment comes from a deep respect for those who have lived
              their lives, made sacrifices, and contributed to society in
              countless ways.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Now, it’s our turn to ensure that their remaining years are filled
              with warmth, dignity, and a sense of belonging. We are here to
              remind them that they are not forgotten.
            </Text>
          </VStack>
          <Image src={Donation3} />
        </Flex>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"36px"}
        >
          <Image src={Donation4} />

          <VStack
            gap={"40px"}
            h={"full"}
            mx={{ base: "20px", md: "auto" }}
            alignItems={"start"}
          >
            <Text
              w={"full"}
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "24px", md: "36px" }}
              lineHeight={"36px"}
              fontWeight={900}
              maxW={{ base: "full", md: "45vw" }}
              textAlign={{ base: "center", md: "start" }}
              textTransform={"capitalize"}
            >
              Animal Welfare
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Jay’s compassion also extends to the voiceless—animals who suffer
              without the ability to express their pain.{" "}
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              AChosenSon Charitable Trust is committed to creating a world where
              every living being, no matter how small, is treated with kindness
              and respect. We focus on providing shelter, food, and medical care
              to animals in need, rescuing those abandoned or mistreated, and
              working toward creating a safe environment for them.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Our vision is not just to rescue animals but to create awareness
              and a sense of responsibility among people to treat all creatures
              with care. With every donation, we aim to expand our reach,
              ensuring that more animals receive the protection and care they
              need.
            </Text>
            <Text
              w={"full"}
              textAlign={{ base: "center", md: "start" }}
              fontSize={{ base: "15px", md: "20px" }}
              lineHeight={"28px"}
              fontWeight={400}
              maxW={{ base: "full", md: "45vw" }}
            >
              Jay has always believed that the way we treat our animals reflects
              the depth of our humanity. Through this mission, he hopes to
              inspire others to see animals not just as creatures but as beings
              deserving of love and kindness.
            </Text>
          </VStack>
        </Flex>
      </Flex>

      <Flex
        mt={{ base: 0, md: "175px" }}
        bg={"#FDE9CF"}
        className={c2}
        p={"55px"}
        paddingTop={{ base: "100px", md: "55px" }}
        paddingBottom={{ base: "100px", md: "55px" }}
        w={"full"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDir={"column"}
        gap={"55px"}
      >
        <VStack gap={"26px"}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "30px", md: "55px" }}
            fontWeight={900}
            w={"full"}
            textAlign={"center"}
            textTransform={"capitalize"}
            lineHeight={{ base: "30px", md: "82px" }}
            maxW={{ base: "full", md: "70vw" }}
          >
            Transparency with a Purpose
          </Text>
          <Text
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "15px", md: "20px" }}
            fontWeight={400}
            w={"full"}
            textAlign={"center"}
            textTransform={"capitalize"}
            lineHeight={{ base: "22px", md: "28px" }}
            maxW={{ base: "full", md: "70vw" }}
          >
            We believe in complete transparency with our donors. When you choose
            to donate, you’ll be able to select the cause that is closest to
            your heart—whether it’s supporting orphans, elderly people, or
            animals.
          </Text>
          <Text
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "15px", md: "20px" }}
            fontWeight={400}
            w={"full"}
            textAlign={"center"}
            textTransform={"capitalize"}
            lineHeight={{ base: "22px", md: "28px" }}
            maxW={{ base: "full", md: "70vw" }}
          >
            For every donation, you’ll receive photos and videos showing how
            your contribution is making a difference.
          </Text>
          <Text
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "15px", md: "20px" }}
            fontWeight={400}
            w={"full"}
            textAlign={"center"}
            textTransform={"capitalize"}
            lineHeight={{ base: "22px", md: "28px" }}
            maxW={{ base: "full", md: "70vw" }}
          >
            Additionally, we’ll provide you with a certificate detailing how
            many lives your generosity has impacted. We want you to feel a
            genuine connection with the cause you support and know that your
            contribution is truly changing lives. Together, let’s create a world
            where no one feels abandoned, forgotten, or unloved.
          </Text>
        </VStack>
      </Flex>
      <Footer viewRatings={false} />
    </>
  );
};

export default Donation;
