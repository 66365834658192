import { Button, TextArea, TextBox } from "@astrology/shared-components";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Flex,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import bottomFrame from "../../images/BottomFrame.svg";
import C5 from "../../images/contactUs-5.svg";
import bg2 from "../../images/ContactUsBg.svg";
import { ReactComponent as InstaIcon } from "../../images/insta.svg";
import Background1 from "../../images/Service1Background.svg";
import topFrame from "../../images/TopFrame.svg";
import { ReactComponent as WhatsAppIcon } from "../../images/whatsappIcon.svg";
import useNavigateWithSection from "../../services/navigateWithSection";
import Footer from "../Footer/Footer";
import OurServices from "./OurServices";
import axios from "axios";

const ContactUs = () => {
  const { navigateToSection } = useNavigateWithSection();
  const toast = useToast();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const sendMail = async () => {
    setLoading(true);
    const payload = {
      name: name ?? "",
      email: email ?? "",
      phone: phoneNumber ?? "",
      message: message ?? "",
    };
    console.log(payload);
    const response = await axios.post(
      `https://api.achosenson.com/send-email`,
      payload
    );
    if (response.status === 200) {
      toast({
        title: "",
        description: "Thank you for contacting us!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "",
        description: "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    reset();
    setLoading(false);
  };

  const reset = () => {
    setName();
    setEmail();
    setPhoneNumber();
    setMessage();
  };

  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        h={{ base: "25vh", md: "35vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={{ base: "end", md: "center" }}
        flexDir={"column"}
      >
        <Flex
          width={{ base: "full", md: "80vw" }}
          alignItems={"center"}
          justifyContent={"end"}
          flexDir={"column"}
          paddingBottom={"50px"}
        >
          <Text
            fontSize={{ base: "26px", md: "57px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"70px"}
            maxW={{ base: "100%", md: "50%" }}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            Contact Our Team
          </Text>
        </Flex>
      </Flex>

      <form onSubmit={sendMail}>
        {/* web view */}
        <SimpleGrid
          bg={"#FFFFFF"}
          w={{ base: "full", md: "70%" }}
          padding={"8px"}
          marginY={"100px"}
          // marginX={{ basae: "2", md: "auto" }}
          marginX={"auto"}
          columns={{ sm: 1, md: 2 }}
          spacing={5}
          gap={5}
          display={{ base: "none", md: "grid" }}
        >
          <VStack
            bgImage={`url('${Background1}')`}
            backgroundPosition={"top"}
            objectFit={"cover"}
            borderRadius={"4px"}
            padding={5}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Flex flexDir={"column"} gap={5}>
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={"36px"}
                color={"#68030D"}
              >
                We'd Love To Hear
              </Text>
              <Text fontSize={"16px"} fontWeight={"normal"} color={"#68030D"}>
                Reach out to us at
              </Text>
              <HStack gap={2}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Customer support - Consultation)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Customer support - Written notes)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Sales team)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2}>
                <EmailIcon color={"#68030D"} fontSize={"20px"} />
                <Text fontSize={"20px"} fontWeight={"normal"} color={"#68030D"}>
                  support@achosenson.com
                </Text>
              </HStack>
            </Flex>
            <HStack marginTop={"30px"}>
              <IconButton
                variant={"ghost"}
                icon={<WhatsAppIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://wa.me/+919601465169", "_blank");
                }}
              />
              <IconButton
                variant={"ghost"}
                icon={<InstaIcon />}
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/achosenson?igsh=OTBvMG11aWw4ZHV2";
                }}
              />
            </HStack>
          </VStack>
          <VStack
            background={"#FFFFFF"}
            boxShadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.10)"}
            border={"1px solid rgba(0, 0, 0, 0.05)"}
            alignItems={"start"}
            w={"full"}
            justifyContent={"space-between"}
            borderRadius={"12px"}
            p={{ base: "3", md: "auto" }}
            h={"full"}
          >
            <Flex gap={2} flexDir={"column"} w={"full"}>
              <TextBox
                type="text"
                name="Your Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                showPlaceHolder
                placeholder="Enter Name"
                required
                hideHelperText={true}
                widthFull={"full"}
              />
              <HStack justifyContent={"space-between"}>
                <TextBox
                  type="email"
                  name="Email Address"
                  showPlaceHolder
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                  hideHelperText={true}
                  widthFull={"full"}
                />
                <TextBox
                  type="tel"
                  name="Phone Number"
                  showPlaceHolder
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  placeholder="Enter no"
                  required
                  hideHelperText={true}
                  widthFull={"full"}
                />
              </HStack>
              <TextArea
                type="text"
                name="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                showPlaceHolder
                placeholder="Write here"
                required
                hideHelperText={true}
                widthFull={"full"}
              />
            </Flex>
            <Button
              type={"submit"}
              variant={"trPrimary"}
              title={"Send a message"}
              w={"full"}
              borderRadius={"8px"}
              padding={"8px 14px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"24px"}
              onClick={sendMail}
              isLoading={loading}
            />
          </VStack>
        </SimpleGrid>

        {/* mobile view */}
        <SimpleGrid
          bg={"#FFFFFF"}
          w={{ base: "full", md: "70%" }}
          padding={"8px"}
          marginTop={"50px"}
          marginBottom={"100px"}
          // marginX={{ basae: "2", md: "auto" }}
          marginX={"auto"}
          columns={1}
          spacing={5}
          gap={5}
          display={{ md: "none" }}
        >
          <VStack
            background={"#FFFFFF"}
            boxShadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.10)"}
            border={"1px solid rgba(0, 0, 0, 0.05)"}
            alignItems={"start"}
            w={"full"}
            justifyContent={"space-between"}
            borderRadius={"12px"}
            p={{ base: "3", md: "auto" }}
            h={"full"}
          >
            <Flex gap={6} flexDir={"column"} w={"full"}>
              <TextBox
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                name="Your Name"
                showPlaceHolder
                placeholder="Enter Name"
                required
                hideHelperText={true}
                widthFull={"full"}
              />
              <TextBox
                type="email"
                name="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                showPlaceHolder
                placeholder="Enter email"
                required
                hideHelperText={true}
                widthFull={"full"}
              />
              <TextBox
                type="tel"
                name="Phone Number"
                showPlaceHolder
                placeholder="Enter no"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                required
                hideHelperText={true}
                widthFull={"full"}
              />

              <TextArea
                type="text"
                value={message}
                name="Message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                showPlaceHolder
                placeholder="Write here"
                required
                hideHelperText={true}
                widthFull={"full"}
              />
            </Flex>
            <Button
              type={"submit"}
              variant={"trPrimary"}
              title={"Send a message"}
              w={"full"}
              borderRadius={"8px"}
              padding={"8px 14px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"24px"}
              isLoading={loading}
              onClick={sendMail}
            />
          </VStack>
          <VStack
            mt={4}
            bgImage={`url('${Background1}')`}
            backgroundPosition={"top"}
            objectFit={"cover"}
            borderRadius={"4px"}
            padding={5}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex flexDir={"column"} gap={5} w={"full"}>
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={"30px"}
                color={"#68030D"}
                textAlign="center"
              >
                We'd Love To Hear
              </Text>
              <Text
                fontSize={"16px"}
                fontWeight={"normal"}
                color={"#68030D"}
                textAlign="center"
              >
                Reach out to us at
              </Text>
              <HStack gap={2} mx={4} w={"full"}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Customer support - Consultation)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2} mx={4} w={"full"}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Customer support - Written notes)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2} mx={4} w={"full"}>
                <PhoneIcon color={"#68030D"} fontSize={"20px"} />
                <VStack gap={0} alignItems={"start"}>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    +91 96014 65169
                  </Text>
                  <Text
                    fontSize={"20px"}
                    fontWeight={"normal"}
                    color={"#68030D"}
                  >
                    (Sales team)
                  </Text>
                </VStack>
              </HStack>
              <HStack gap={2} mx={4} w={"full"}>
                <EmailIcon color={"#68030D"} fontSize={"20px"} />
                <Text fontSize={"20px"} fontWeight={"normal"} color={"#68030D"}>
                  support@achosenson.com
                </Text>
              </HStack>
            </Flex>
            <HStack marginTop={"30px"}>
              <IconButton
                variant={"ghost"}
                icon={<WhatsAppIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://wa.me/+919601465169", "_blank");
                }}
              />
              <IconButton
                variant={"ghost"}
                icon={<InstaIcon />}
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/achosenson?igsh=OTBvMG11aWw4ZHV2";
                }}
              />
            </HStack>
          </VStack>
        </SimpleGrid>
      </form>

      <OurServices />
      <Flex
        my={20}
        mx={"auto"}
        w={{ base: "90vw", md: "70vw" }}
        h={{ base: "95vh", md: "65vh" }}
        backgroundColor={"#FFF5E8"}
        bgImage={{ base: "", md: bg2 }}
        background={{ base: `${topFrame} ${bottomFrame}`, md: bg2 }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-around"}
        flexDir={"column"}
      >
        <Image src={topFrame} display={{ base: "inherit", md: "none" }} />

        <Image src={C5} />
        <Flex mx={"auto"} flexDir={"column"} alignItems={"center"} gap={2}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "24px", md: "36px" }}
            fontWeight={600}
            lineHeight={"70px"}
            textTransform={"capitalize"}
          >
            Work with us
          </Text>
          <Text
            fontSize={{ base: "16px", md: "20px" }}
            fontWeight={400}
            lineHeight={"28px"}
            textTransform={"capitalize"}
            textAlign={"center"}
            maxW={"70%"}
          >
            Work with Team Jay and help people transform their lives! We are
            always looking for hustlers to join our team.
          </Text>
          <Button
            variant={"trPrimary"}
            title={"Open positions"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            marginTop={"20px"}
            onClick={() => {
              window.open(
                "https://perfect-postbox-bae.notion.site/Work-with-us-1183b7cedce080b586fccd35664a6fa9?pvs=4",
                "_blank"
              );
            }}
          />
        </Flex>
        <Image src={bottomFrame} display={{ base: "inherit", md: "none" }} />
      </Flex>
      <Footer />
    </>
  );
};

export default ContactUs;
