import { Button } from "@astrology/shared-components";
import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import C1 from "../../images/Collaboration-1.svg";
import Background1 from "../../images/Service1Background.svg";
import Footer from "../Footer/Footer";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

const Collaboration = () => {
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        // h={{ base: "30vh", md: "40vh" }}
        py={"100px"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"end"}
        flexDir={"column"}
      >
        <Flex
          width={{ base: "full", md: "80vw" }}
          alignItems={"center"}
          justifyContent={"end"}
          flexDir={"column"}
          // paddingBottom={"50px"}
        >
          <Text
            fontSize={{ base: "26px", md: "57px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"70px"}
            // maxW={{ base: "100%", md: "50%" }}
            textAlign={"center"}
            fontFamily={"Fondamento"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            How To Collaborate?
          </Text>
          <Text
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            color={"#68030D"}
            lineHeight={{ base: "21px", md: "37px" }}
            maxW={"60%"}
          >
            Let's transform lives together.
          </Text>
        </Flex>
      </Flex>
      <Flex
        bg={{ base: "#FDE9CF", md: "#FFFFFF" }}
        alignItems={"center"}
        justifyContent={"center"}
        flexDir={"column"}
        mx={"auto"}
        my={{ base: "0", md: "40px" }}
        py={{ base: "40px", md: "0px" }}
        gap={"40px"}
      >
        <Flex
          mx={{ base: "5", md: "345px" }}
          my={"auto"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          gap={5}
        >
          <Image src={C1} />
          {/* <Text
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight={"400"}
            color={"#68030D"}
            // maxW={{ base: "87%", md: "80%" }}
            textAlign={{ base: "justify", md: "justify" }}
          >
            Jay’s profound understanding of Astrology, Numerology and his
            dedication to impact lives makes him an ideal speaker to bring
            astrology to audiences in its simplest and purest form.
          </Text>
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight={"400"}
            color={"#68030D"}
            // maxW={{ base: "77%", md: "70%" }}
            textAlign={{ base: "justify", md: "justify" }}
          >
            With a remarkable gift for storytelling, Jay presents astrology in
            its most simplified form that connects with people of all ages and
            backgrounds. His crisp clarity on complex concepts and accurate
            guidance has earned him the title of India’s most loved. Join him on
            the journey of impacting lives and integrating astrology into daily
            life.
          </Text> */}
          <Button
            leftIcon={<PhoneIcon />}
            bgImage={`url('${Background1}')`}
            backgroundColor={"#9C541C"}
            backgroundPosition={"top"}
            title={"+91 96014 65169"}
            borderRadius={"8px"}
            paddingX={4}
            paddingY={3}
            height={"fit-content"}
            textTransform={"initial"}
            color={"#68030D"}
            fontSize={{ base: "18px", md: "25px" }}
            lineHeight={"36px"}
            fontWeight={400}
            fontFamily={"Jost"}
            _hover={{}}
            border={"none"}
            onClick={() => {
              window.location.href = "tel:+919601465169";
            }}
          />
          <Button
            leftIcon={<EmailIcon />}
            bgImage={`url('${Background1}')`}
            backgroundColor={"#9C541C"}
            backgroundPosition={"top"}
            title={"collaborations@achosenson.com"}
            borderRadius={"8px"}
            paddingX={4}
            paddingY={3}
            height={"fit-content"}
            textTransform={"initial"}
            color={"#68030D"}
            fontSize={{ base: "18px", md: "25px" }}
            lineHeight={"36px"}
            fontWeight={400}
            fontFamily={"Jost"}
            _hover={{}}
            border={"none"}
            onClick={() => {
              window.location.href = "mailto:collaborations@achosenson.com";
            }}
          />
        </Flex>
        <Footer />
      </Flex>
    </>
  );
};

export default Collaboration;
