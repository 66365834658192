import React from "react";
import S1 from "../image/Service-1.png";
import S2 from "../image/Service-2.png";
import S3 from "../image/Service-3.png";
import S4 from "../image/Service-4.png";
import { Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Button } from "@astrology/shared-components";
import useNavigateWithSection from "../services/navigateWithSection";

const textSize = {
  base: "18px",
  sm: "18px",
  md: "20px",
  lg: "24px",
  xl: "26px",
  "2xl": "26px",
};

const btnTextSize = {
  base: "16px",
  sm: "16px",
  md: "14px",
  lg: "16px",
  xl: "18px",
  "2xl": "20px",
};

const ServiceContentArray = [
  {
    image: S1,
    title: "Consultation with Jay",
    content:
      "Talk to Jay and get answers to all your questions about your career, relationship, business and beyond.",
    btnText: "Consult Now!",
    btnFunction: ["/services/consultation", "consultation-booking"],
  },
  {
    image: S2,
    title: "Written Notes",
    content:
      "Ask any life question and get in-depth, personalized solutions in an easy-to-understand PDF report by our team of experts in 72 hours.",
    btnText: "Book Now!",
    btnFunction: ["/services/notes", "notes-booking"],
  },
  {
    image: S3,
    title: "Report",
    content:
      "Get your detailed Kundli analysis including all the charts and predictions on all areas of your life in 48 hours",
    btnText: "Order Now!",
    btnFunction: ["/services/reports", ""],
  },
  {
    image: S4,
    title: "Numerology based solutions",
    content:
      "Get Name and Business Name correction, House, mobile, and vehicle number correction in 48 hours, and change your fortune!",
    btnText: "Contact Now!",
    btnFunction: ["/services/numerology", ""],
  },
];

const CommonServices = () => {
  const { navigateToSection } = useNavigateWithSection();

  return (
    <SimpleGrid
      alignSelf={"end"}
      columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
      gap={{ base: "6", md: "15" }}
      paddingX={{ base: "4", md: "4", lg: "8" }}
    >
      {ServiceContentArray?.map((ele) => (
        <VStack gap={2} justifyContent={"space-between"}>
          <VStack gap={2}>
            <Image src={ele.image} height={"200px"} />
            <Text
              fontFamily={"Fondamento"}
              color="#68030D"
              textAlign="center"
              fontSize={textSize}
              fontWeight={800}
              textTransform={"capitalize"}
            >
              {ele?.title}
            </Text>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={{ base: "justify", md: "center" }}
            >
              {ele?.content}
            </Text>
          </VStack>
          <VStack>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={btnTextSize}
              fontWeight={500}
              title={ele?.btnText}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                navigateToSection(...ele?.btnFunction);
              }}
            />
          </VStack>
        </VStack>
      ))}
    </SimpleGrid>
  );
};

export default CommonServices;
