import { Button } from "@astrology/shared-components";
import {
  Box,
  Flex,
  HStack,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import C1 from "../../images/Calendar.svg";
import C2 from "../../images/Calendar2.svg";
import Career2 from "../../images/Career-2.svg";
import Career3 from "../../images/Career-3.svg";
import Career4 from "../../images/Career-4.svg";
import Career5 from "../../images/Career-5.svg";
import Career6 from "../../images/Career-6.svg";
import Career from "../../images/Career.svg";
import { ReactComponent as CheckIcon } from "../../images/checkMark.svg";
import Background4 from "../../images/fourthSection.svg";
import S1 from "../../images/Service-1.svg";
import S2 from "../../images/Service-2.svg";
import S3 from "../../images/Service-3.svg";
import S4 from "../../images/Service-4.svg";
import Background2 from "../../images/Service-one-on-one-section-2.png";
import Background1 from "../../images/Service1Background.svg";
import S5 from "../../images/serviceSection.svg";
import Background3 from "../../images/thirdSection.svg";
import Footer from "../Footer/Footer";

const Services = () => {
  return (
    <Box maxH={"100vh"} overflow={"auto"}>
      <Flex
        bgImage={`url('${Background1}')`}
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        flexDir={"column"}
        backgroundPosition={"top"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          gap={{ base: "20px", md: "50px" }}
          mt={"70px"}
        >
          <Text
            fontSize={{ base: "26px", md: "57px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"70px"}
            maxW={{ base: "80%", md: "80%" }}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            Get In Touch With Jay Singh Zala For Your Consultation.
          </Text>
          <Text
            fontSize={{ base: "15px", md: "24px" }}
            fontWeight={400}
            color={"#68030D"}
            lineHeight={{ base: "21px", md: "37px" }}
            maxW={"60%"}
            mt={"12px"}
          >
            50k + people changed their lives
          </Text>
          <SimpleGrid
            columns={{ base: 2, md: 4 }}
            mx={"auto"}
            gap={{ base: "24px", md: "48px" }}
          >
            <VStack gap={0}>
              <Image src={S1} title="Astrology" />
              <Text
                fontSize={"24px"}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Astrology
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image src={S2} title="Numerology" />
              <Text
                fontSize={"24px"}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Numerology
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image src={S3} title="Transformation" />
              <Text
                fontSize={"24px"}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Transformation
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image src={S4} title="Remedies" />
              <Text
                fontSize={"24px"}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Remedies
              </Text>
            </VStack>
          </SimpleGrid>
        </Flex>
        <VStack
          alignContent={"center"}
          justifyContent={"end"}
          mt={{ base: "25px", md: "50px" }}
        >
          <Button
            variant={"trPrimary"}
            title={"Book one-on-one consultation now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
      </Flex>

      <VStack
        justifyContent={"space-evenly"}
        bgRepeat="no-repeat"
        backgroundSize={"contain"}
        w={"full"}
        mb={8}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          marginTop={"100px"}
        >
          <Text
            fontSize={{ base: "26px", md: "36px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={{ base: "42px", md: "59px" }}
            fontFamily={"Fondamento, sans-serif"}
            textAlign={"center"}
            fontWeight={900}
          >
            Make The Most Out Of Your Planetary Influences.
          </Text>
        </Flex>
        <HStack justifyContent={"space-around"} w={"90vw"}>
          <Flex
            flexDir={"column"}
            justifyContent={"space-between"}
            gap={{ base: "25px", md: "50px" }}
          >
            <UnorderedList>
              <ListItem color={"#68030D"}>
                <Text
                  fontSize={{ base: "16px", md: "29px" }}
                  fontWeight={400}
                  lineHeight={{ base: "24px", md: "48px" }}
                  color={"#68030D"}
                  stroke={"#68030D"}
                >
                  Improve your Love life
                </Text>
              </ListItem>
              <ListItem color={"#68030D"}>
                <Text
                  fontSize={{ base: "16px", md: "29px" }}
                  fontWeight={400}
                  lineHeight={{ base: "24px", md: "48px" }}
                  color={"#68030D"}
                  stroke={"#68030D"}
                >
                  Your Career - Ways that can generate more income for you
                </Text>
              </ListItem>
              <ListItem color={"#68030D"}>
                <Text
                  fontSize={{ base: "16px", md: "29px" }}
                  fontWeight={400}
                  lineHeight={{ base: "24px", md: "48px" }}
                  color={"#68030D"}
                  stroke={"#68030D"}
                >
                  Life path for students
                </Text>
              </ListItem>
              <ListItem color={"#68030D"}>
                <Text
                  fontSize={{ base: "16px", md: "29px" }}
                  fontWeight={400}
                  lineHeight={{ base: "24px", md: "48px" }}
                  color={"#68030D"}
                  stroke={"#68030D"}
                >
                  Couple Astrological Reading
                </Text>
              </ListItem>
              <ListItem color={"#68030D"}>
                <Text
                  fontSize={{ base: "16px", md: "29px" }}
                  fontWeight={400}
                  lineHeight={{ base: "24px", md: "48px" }}
                  color={"#68030D"}
                  stroke={"#68030D"}
                >
                  Family Reading
                </Text>
              </ListItem>
            </UnorderedList>
            <Button
              variant={"trPrimary"}
              title={"Know your Strength"}
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              height={"36px"}
              width={{ base: "full", md: "200px" }}
            />
          </Flex>
          <Image src={Background2} display={{ base: "none", md: "inherit" }} />
        </HStack>
      </VStack>

      <VStack
        bgImage={`url('${Background3}')`}
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          mr={"50px"}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"70px"}
          >
            Change your life with a single call
          </Text>
          <Flex gap={"5"} flexDir={"column"}>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"90%"}
            >
              Your planetary position has a mission to realign you with your
              life purpose.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"75%"}
            >
              Jay being “A Chosen Son” is here to guide you through your
              transformational journey.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"40px"}
              fontWeight={800}
              lineHeight={"30px"}
              maxW={"75%"}
              my={5}
            >
              सर्वे कर्मवशा वयम् |
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"75%"}
            >
              “Everything is subject to Karma”
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Take the charge of your Transformation Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </Flex>
      </VStack>

      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        display={{ base: "flex", md: "none" }}
      >
        <Flex
          bgImage={`url('${Background3}')`}
          w={"full"}
          h={"540px"}
          backgroundPosition={"left"}
          backgroundSize={"cover"}
          display={{ base: "flex", md: "none" }}
        ></Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"30px"}
            textTransform={"capitalize"}
            fontWeight={900}
            // maxW={"60%"}
            textAlign={"center"}
            lineHeight={"40px"}
            letterSpacing={"1.5px"}
          >
            Change your life with a single call
          </Text>
          <Flex gap={"5"} flexDir={"column"} w={"full"}>
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              textAlign={"center"}
            >
              Your planetary position has a mission to realign you with your
              life purpose.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              textAlign={"center"}
            >
              Jay being “A Chosen Son” is here to guide you through your
              transformational journey.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"40px"}
              fontWeight={800}
              lineHeight={"20px"}
              textAlign={"center"}
              my={5}
            >
              सर्वे कर्मवशा वयम् |
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              textAlign={"center"}
            >
              “Everything is subject to Karma”
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Take the charge of your Transformation Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
          />
        </Flex>
      </VStack>

      <VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Career
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Find which career is going to get you success.
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Feeling lost about what career to choose? Stuck in your career
                without any growth? Want to know which career will bring the
                most success to your life? Talk to Jay and get answers on the
                best career options according to your birth chart and planetary
                influences. Find a career with maximum growth and potential.
              </Text>
            </Flex>
          </Flex>
          <Image src={Career} />
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Career
          </Text>
          <Image src={Career} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Find which career is going to get you success.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Feeling lost about what career to choose? Stuck in your career
              without any growth?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Want to know which career will bring the most success to your
              life?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Talk to Jay and get answers on the best career options according
              to your birth chart and planetary influences.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Find a career with maximum growth and potential.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Career Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career2} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Love & Relationship
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Love is simple, we complicate it. Find out how you can enhance
                your love life?
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Looking for a life partner? Or going through a breakup after a
                long relationship? Talk to Jay and get personalized insight into
                your romantic relationships. Find practical solutions to solve
                your relationship issues and foster healthier, and fulfilling
                connections.
              </Text>
            </Flex>
          </Flex>
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Love & Relationship
          </Text>
          <Image src={Career2} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Love is simple, we complicate it. Find out how you can enhance
              your love life?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Looking for a life partner? Or going through a breakup after a
              long relationship?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Talk to Jay and get personalized insight into your romantic
              relationships.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Find practical solutions to solve your relationship issues and
              foster healthier, and fulfilling connections.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Love Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
      </VStack>

      <VStack
        bgImage={`url('${Background1}')`}
        w={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        backgroundPosition={"top"}
      >
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Health
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Save yourself from health issues before you know it.
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Suffering from health issues or want to prevent health issues in
                future? Talk to Jay and identify potential areas of health
                concerns. Start taking care before you experience any symptoms
                and resolve your health issues by balancing the planetary
                influences.
              </Text>
            </Flex>
          </Flex>
          <Image src={Career3} />
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            health
          </Text>
          <Image src={Career3} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Save yourself from health issues before you know it.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Suffering from health issues or want to prevent health issues in
              future?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Talk to Jay and identify potential areas of health concerns.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Start taking care before you experience any symptoms and resolve
              your health issues by balancing the planetary influences.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Health Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career4} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Business
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Feeling stuck in your business? Want to know if your new business
              will succeed? Get personalized advice based on your birth chart.
              Discover your strengths, weaknesses, and the best path forward to
              achieve your goals. Align your hard work in the right direction
              and watch yourself grow in business.
            </Text>
          </Flex>
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Business
          </Text>
          <Image src={Career4} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Feeling stuck in your business? Want to know if your new business
              will succeed?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Get personalized advice based on your birth chart. Discover your
              strengths, weaknesses, and the best path forward to achieve your
              goals.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Align your hard work in the right direction and watch yourself
              grow in business.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Business Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
      </VStack>

      <VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Marriage
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Marriage is a union of two people who have the highest karmic
              debts with each other. It is important to ensure both partners
              have compatibility and they don’t face any issues based on each
              other's charts. This process of kundli matching, analysis of
              marriage problems helps in recognizing your partner. And solve
              issues in current marriages.
            </Text>
          </Flex>
          <Image src={Career5} />
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Marriage
          </Text>
          <Image src={Career5} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Marriage is a union of two people who have the highest karmic
              debts with each other.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              It is important to ensure both partners have compatibility and
              they don’t face any issues based on each other's charts.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              This process of kundli matching, analysis of marriage problems
              helps in recognizing your partner. And solve issues in current
              marriages.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Marraige Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career6} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Family consultation
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Talk to Jay and gain insights into your family dynamics through
              astrology. Understand the karmic connections between family
              members and find practical solutions to resolve conflicts. Jay’s
              guidance can help restore balance, foster love, and nurture
              stronger bonds within your family. Discover the areas that need
              attention and unlock the potential for peace and happiness in your
              household.
            </Text>
          </Flex>
        </HStack>
        <VStack gap={"23px"} py={7} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={900}
            lineHeight={"70px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Family consultation
          </Text>
          <Image src={Career6} w={"250px"} height={"235px"} />
          <Flex flexDir={"column"} gap={3} maxW={"90%"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Talk to Jay and gain insights into your family dynamics through
              astrology.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Understand the karmic connections between family members and find
              practical solutions to resolve conflicts.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Jay’s guidance can help restore balance, foster love, and nurture
              stronger bonds within your family.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={500}
              lineHeight={"22px"}
            >
              Discover the areas that need attention and unlock the potential
              for peace and happiness in your household.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Family Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </VStack>
      </VStack>

      <VStack
        bgImage={`url('${Background4}')`}
        w="full"
        h={{ base: "full", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize="contain" // Or use "cover" or "100% 100%" based on your need
        align="flex-start"
        justify="center"
        direction="column"
        bgPosition="center" // Centering the image vertically and horizontally
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          ml={"80px"}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"70px"}
          >
            Benefits of a session with Jay
          </Text>
          <Flex gap={"3"} flexDir={"column"}>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Engage directly with Jay for personalized guidance.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Receive a comprehensive astrological analysis for your concerns
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Discover practical and tailored solutions to overcome life’s
                challenges
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Harness the power of planetary influences to unlock your full
                potential
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Gain insight into past challenges and discover actionable steps
                for an improved future
              </Text>
            </HStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Your Personalized Consultation Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
          />
        </Flex>
      </VStack>

      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        alignItems={"start"}
        justifyContent={"start"}
        display={{ base: "flex", md: "none" }}
      >
        <Flex
          bgImage={`url('${Background4}')`}
          w={"120%"}
          h={"540px"}
          backgroundPosition={"right"}
          backgroundSize={"cover"}
          display={{ base: "flex", md: "none" }}
        ></Flex>
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <HStack>
            <CheckIcon fontSize={"15px"} />
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              w={"90%"}
            >
              Engage directly with Jay for personalized guidance.
            </Text>
          </HStack>
          <HStack>
            <CheckIcon fontSize={"15px"} />
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              w={"90%"}
            >
              Receive a comprehensive astrological analysis for your concerns
            </Text>
          </HStack>
          <HStack>
            <CheckIcon fontSize={"15px"} />
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              w={"90%"}
            >
              Discover practical and tailored solutions to overcome life’s
              challenges
            </Text>
          </HStack>
          <HStack>
            <CheckIcon fontSize={"15px"} />
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              w={"90%"}
            >
              Harness the power of planetary influences to unlock your full
              potential
            </Text>
          </HStack>
          <HStack>
            <CheckIcon fontSize={"15px"} />
            <Text
              fontFamily={"Jost"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"20px"}
              w={"90%"}
            >
              Gain insight into past challenges and discover actionable steps
              for an improved future
            </Text>
          </HStack>
          <Button
            variant={"trPrimary"}
            title={"Book Personalized Consultation"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
          />
        </Flex>
      </VStack>

      <VStack my={"10"} gap={"23px"}>
        <Text
          mx={"auto"}
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "24px", md: "36px" }}
          fontWeight={900}
          lineHeight={{ base: "normal", md: "70px" }}
          textTransform={"capitalize"}
          textAlign={"center"}
          maxW={"80%"}
        >
          One call can change everything—if it’s with the right person
        </Text>
        <Text
          mx={"auto"}
          fontSize={{ base: "14px", md: "20px" }}
          fontWeight={400}
          lineHeight={{ base: "20px", md: "28px" }}
          maxW={"75%"}
          textAlign={"center"}
        >
          With Jay, that call unlocks the solutions to your toughest life
          challenges. He listens, reveals the real reasons behind your
          struggles, and provides practical solutions tailored just for you.
          Make the call today and embrace the change and the transformations
          filled with success and harmony. See here Terms & Conditions
        </Text>
      </VStack>

      <SimpleGrid
        columns={{ sm: 1, md: 3 }}
        gap={8}
        marginY={"50px"}
        mx={{ base: "4", md: "125px" }}
        spacing={"4"}
      >
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              10 minutes Auio Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Ask pressing questions and see Jay as he provides personalized
              guidance.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              20 minutes audio Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Explore 3-4 areas of your life with more detail and guidance.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              30 minutes audio Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Dive deep into all aspects of your life for thorough insights.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
      </SimpleGrid>

      <SimpleGrid
        columns={{ sm: 1, md: 3 }}
        gap={8}
        py={"50px"}
        mx={{ base: "4", md: "125px" }}
        spacing={"4"}
        bgImage={{ base: `url(${Background1})`, md: "" }}
        bgPosition={"center"}
      >
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              10 minutes video Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Ask pressing questions and see Jay as he provides personalized
              guidance.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              20 minutes video Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Discuss 3-4 key areas of your life face-to-face with Jay for
              deeper clarity.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
        <VStack
          gap={2}
          h={"fit-content"}
          justifyContent={"space-between"}
          height={"full"}
        >
          <>
            <Image src={S5} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              30 minutes video Regular
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"center"}
            >
              Go in-depth on all aspects of your life face-to-face with Jay.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </>
        </VStack>
      </SimpleGrid>

      <Flex
        alignSelf={"center"}
        w={"full"}
        mx={"auto"}
        justifyContent={"space-evenly"}
        my={"50px"}
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: 5, md: 0 }}
      >
        <VStack gap={"47px"} w={{ base: "90%", md: "30%" }}>
          <Image src={C1} />
          <VStack
            justifyContent={"space-between"}
            gap={{ base: "5", md: "80px" }}
          >
            <VStack gap={2}>
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={"24px"}
                fontWeight={700}
                lineHeight={"normal"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Urgent in next 2 months
              </Text>
              <Text
                fontSize={"20px"}
                textAlign={"center"}
                fontWeight={400}
                lineHeight={"28px"}
              >
                Secure your slot within 3 months to address your pressing
                concerns.
              </Text>
            </VStack>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </VStack>
        </VStack>
        <VStack gap={"26px"} w={{ base: "90%", md: "30%" }}>
          <Image src={C2} />
          <VStack
            justifyContent={"space-between"}
            gap={{ base: "5", md: "80px" }}
          >
            <VStack gap={2}>
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={"24px"}
                fontWeight={700}
                lineHeight={"normal"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Premium in current month
              </Text>
              <Text
                fontSize={"20px"}
                textAlign={"center"}
                fontWeight={400}
                lineHeight={"28px"}
              >
                Skip the wait and get immediate guidance when you need it most.
              </Text>
            </VStack>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consultation Now"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            />
          </VStack>
        </VStack>
        <Footer viewRatings={true} />
      </Flex>
    </Box>
  );
};

export default Services;
