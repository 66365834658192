import { Button } from "@astrology/shared-components";
import {
  Box,
  Flex,
  HStack,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import border from "../../../images/border.png";
import { ReactComponent as CheckIcon } from "../../../images/checkMark.svg";
import N1 from "../../../images/note-1.svg";
import N2 from "../../../images/note-2.svg";
import N3 from "../../../images/note-3.svg";
import N4 from "../../../images/note-4.svg";
import NI1 from "../../../images/note-illustration-1.svg";
import NI10 from "../../../images/note-illustration-10.svg";
import NI11 from "../../../images/note-illustration-11.svg";
import NI12 from "../../../images/note-illustration-12.svg";
import NI2 from "../../../images/note-illustration-2.svg";
import NI3 from "../../../images/note-illustration-3.svg";
import NI4 from "../../../images/note-illustration-4.svg";
import NI5 from "../../../images/note-illustration-5.svg";
import NI6 from "../../../images/note-illustration-6.svg";
import NI7 from "../../../images/note-illustration-7.svg";
import NI8 from "../../../images/note-illustration-8.svg";
import NI9 from "../../../images/note-illustration-9.svg";
import Background1 from "../../../images/noteBg1.svg";
import Background3 from "../../../images/notes-section-2.svg";
import NotesWorksLine1 from "../../../images/NotesWork-line-1.svg";
import NotesWorksLine2 from "../../../images/NotesWork-line-2.svg";
import SampleNote from "../../../images/PDF_Bg.png";

import { SampleReportText } from "../../../images/pdf/SampleReport.js";
import Background2 from "../../../images/Service1Background.svg";
import useNavigateWithSection from "../../../services/navigateWithSection";
import FAQS from "../../FAQS/FAQS";
import Footer from "../../Footer/Footer";
import Testimonials from "../../Testimonials/Testimonials";
import { benefitsPoints } from "./benefitsPoints";
import ScrollableComponent from "../../../components/ScrollableComponent";
import MobileBackground01 from "../../../images/mobile/written-notes-01.PNG";

const WrittenNotes = () => {
  const { navigateToSection } = useNavigateWithSection();

  const mask1 = useBreakpointValue({
    base: "mask1-mobile", // For base (mobile) screens
    md: "mask1", // For medium screens
  });
  const c1 = useBreakpointValue({
    base: "mob-1", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const mask2Class = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "mask2", // For medium screens
  });

  const c2 = useBreakpointValue({
    base: "mob-2", // For base (mobile) screens
    md: "footer-mask", // For medium screens
  });

  const c3 = useBreakpointValue({
    base: "mob-2", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      {/* section 1 open */}
      {/* webview */}
      <VStack
        bgImage={`url('${Background1}')`}
        className={mask1}
        w={"full"}
        h={{ base: "full", md: "95vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          mr={"50px"}
          flexDir={"column"}
          maxW={"40%"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"40px"}
          >
            Ask Jay and his team any life question!
          </Text>
          <Flex gap={"5"} flexDir={"column"}>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"30px"}
            >
              Get Your Personalized Answer Delivered Straight to Your Inbox!
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
            >
              Looking for quick and practical astrological insights? Our Written
              Notes offer detailed guidance within 20 days—a convenient
              alternative to one-on-one consultations. For urgent questions, get
              personalized solutions delivered in just 72 hours.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"30px"}
            >
              What kind of questions can I ask?
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
            >
              You can ask anything about yourself—your planetary positions, love
              life, career, finances, health, life remedies, spiritual journey,
              foreign settlement, studies, or upcoming transits. No question is
              too small or big—just ask, and our team will help you find the
              answers.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection("/services/notes", "notes-booking");
            }}
          />
        </Flex>
      </VStack>

      {/* mobile view */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        display={{ base: "flex", md: "none" }}
        pb={4}
      >
        <Flex
          bgImage={`url('${MobileBackground01}')`}
          w={"100%"}
          h={"480px"}
          backgroundSize={"cover"}
          backgroundPosition={"center bottom"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={{ base: "flex", md: "none" }}
        ></Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={4}
          flexDir={"column"}
          p={2}
          mx={5}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"28px"}
            textTransform={"capitalize"}
            fontWeight={900}
            textAlign={"center"}
            lineHeight={"40px"}
            letterSpacing={"1.5px"}
          >
            Ask Jay and his team any life question!
          </Text>
          <Flex gap={"5"} flexDir={"column"} w={"full"}>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={600}
              textAlign={"center"}
              color={"#68030D"}
              // maxW={{ base: "87%", md: "80%" }}
            >
              Get Your Personalized Answer Delivered Straight to Your Inbox!
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"justify"}
            >
              Looking for quick and practical astrological insights? Our Written
              Notes offer detailed guidance within 20 days—a convenient
              alternative to one-on-one consultations. For urgent questions, get
              personalized solutions delivered in just 72 hours.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={600}
              textAlign={"center"}
            >
              What kind of questions can I ask?
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={"justify"}
            >
              You can ask anything about yourself—your planetary positions, love
              life, career, finances, health, life remedies, spiritual journey,
              foreign settlement, studies, or upcoming transits. No question is
              too small or big—just ask, and our team will help you find the
              answers.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              navigateToSection("/services/notes", "notes-booking");
            }}
          />
        </Flex>
      </VStack>
      {/* section 1 closed */}

      {/* secion 2 start */}
      <Flex
        w={{ base: "full", md: "80%" }}
        flexDir={{ base: "column-reverse", md: "row" }}
        mx={"auto"}
        my={"50px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={{ base: 3, md: 0 }}
        gap={"45px"}
      >
        <VStack
          gap={4}
          mt={{ base: "10", md: "0" }}
          width={{ base: "100%", md: "70%" }}
          maxW={{ base: "100%", md: "80%" }}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "26px", md: "36px" }}
            fontWeight={900}
            lineHeight={{ base: "35px", md: "70px" }}
            textTransform={"capitalize"}
            textAlign={"center"}
            // maxW={{ base: "60%", md: "90%" }}
          >
            Benefits of Written Notes
          </Text>
          <UnorderedList>
            {benefitsPoints?.map((ele) => {
              return (
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "20px", md: "20px" }}
                    fontWeight={400}
                    lineHeight={{ base: "28px", md: "28px" }}
                    color={"#68030D"}
                    stroke={"#68030D"}
                  >
                    {ele}
                  </Text>
                </ListItem>
              );
            })}
          </UnorderedList>

          {/* webview button */}
          <Button
            variant={"trPrimary"}
            title={"Book Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            display={{ base: "none", md: "inherit" }}
            onClick={() => {
              navigateToSection("/services/notes", "notes-booking");
            }}
          />
          {/* mobile view button */}
          <Button
            variant={"trPrimary"}
            title={"Book Now!"}
            borderRadius={"10px"}
            padding={"3"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            display={{ base: "inherit", md: "none" }}
            onClick={() => {
              navigateToSection("/services/notes", "notes-booking");
            }}
          />
        </VStack>

        <VStack w={"full"}>
          {/* webview */}
          <Box
            // style={{ width: "100%", height: "100vh", overflow: "auto" }}
            style={{
              WebkitUserSelect: "none",
              KhtmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              OUserSelect: "none",
              userSelect: "none",
            }}
            bgImage={`url('${SampleNote}')`}
            w={{ base: "85%", md: "full" }}
            h={{ base: "70vh", md: "105vh" }}
            bgRepeat="no-repeat"
            backgroundSize={"cover"}
            backgroundPosition={"top"}
            display={{ base: "none", md: "flex" }}
            padding={{ base: "2px", md: "85px" }}
          >
            <Box height={"85vh"} overflow="auto">
              <ScrollableComponent htmlContent={SampleReportText} />
            </Box>
          </Box>

          {/* mobile view */}
          <Box
            // style={{ width: "100%", height: "100vh", overflow: "auto" }}
            style={{
              WebkitUserSelect: "none",
              KhtmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              OUserSelect: "none",
              userSelect: "none",
            }}
            bgImage={`url('${SampleNote}')`}
            w={{ base: "85%", md: "full" }}
            h={{ base: "56vh", md: "105vh" }}
            bgRepeat="no-repeat"
            backgroundSize={"cover"}
            backgroundPosition={"top"}
            display={{ base: "flex", md: "none" }}
            paddingX={"30px"}
            alignItems={"center"}
          >
            <Box height={"50vh"} overflow="auto">
              <ScrollableComponent htmlContent={SampleReportText} />
            </Box>
          </Box>
          <Text
            fontSize={"20px"}
            fontWeight={500}
            textAlign={"center"}
            w={"full"}
          >
            Sample of Written Note
          </Text>
        </VStack>
      </Flex>
      {/* section 2 closed */}

      {/* section 3 start */}
      <Flex
        bgImage={`url('${Background2}')`}
        className={mask2Class}
        w={"full"}
        h={{ base: "full", md: "80vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        flexDir={"column"}
        backgroundPosition={"top"}
        pb={5}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          gap={{ base: "20px", md: "50px" }}
          mt={"70px"}
        >
          <Text
            fontSize={{ base: "26px", md: "36px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"70px"}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            What will the PDF contain?
          </Text>

          <SimpleGrid
            columns={{ base: 2, md: 4 }}
            mx={"50px"}
            gap={{ base: "24px", md: "48px" }}
          >
            <VStack gap={3}>
              <Image src={N1} title="Astrology" />
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Comprehensive solutions to your questions
              </Text>
            </VStack>
            <VStack gap={3}>
              <Image src={N2} title="Numerology" />
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Detailed answers to your specific questions
              </Text>
            </VStack>
            <VStack gap={5}>
              <Image src={N3} title="Transformation" />
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Recommended Mantra and relevant remedies
              </Text>
            </VStack>
            <VStack gap={3}>
              <Image src={N4} title="Remedies" />
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
                textAlign={"center"}
              >
                Practical tips to help you navigate challenges
              </Text>
            </VStack>
          </SimpleGrid>
        </Flex>
      </Flex>
      {/* section 3 closed */}

      {/* section 4 start */}
      <div id="notes-booking">
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          w={"full"}
          my={"50px"}
          mx={"auto"}
        >
          <Text
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "24", md: "36px" }}
            fontWeight={900}
            textTransform={"capitalize"}
            lineHeight={{ base: "30px", md: "70px" }}
          >
            How Do Written Notes Work?
          </Text>
        </Flex>
        {/* web view */}
        <SimpleGrid
          columns={{ base: 1, md: 5 }}
          my={"40px"}
          mx={"auto"}
          gap={"84px"}
          display={{ base: "none", md: "grid" }}
          width={{ base: "100%", md: "80%" }}
        >
          <VStack gap={"38px"}>
            <Image src={NI1} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "18px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Make the payment Online
            </Text>
          </VStack>
          <VStack
            gap={"38px"}
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={NotesWorksLine1} />
          </VStack>
          <VStack gap={"38px"}>
            <Image src={NI2} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Ask your questions in the form{" "}
            </Text>
          </VStack>
          <VStack
            gap={"38px"}
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image src={NotesWorksLine2} />
          </VStack>
          <VStack gap={"38px"}>
            <Image src={NI3} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Get detailed answers in PDF format in 72 hours{" "}
            </Text>
          </VStack>
        </SimpleGrid>
        {/* mobile view */}
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          my={"25px"}
          mx={"auto"}
          gap={"84px"}
          display={{ base: "grid", md: "none" }}
        >
          <VStack gap={"38px"}>
            <Image src={NI1} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "18px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Make the payment Online
            </Text>
          </VStack>
          <VStack gap={"38px"}>
            <Image src={NI2} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Ask your questions in the form{" "}
            </Text>
          </VStack>
          <VStack gap={"38px"}>
            <Image src={NI3} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Get detailed answers in PDF format in 72 hours{" "}
            </Text>
          </VStack>
        </SimpleGrid>
      </div>
      {/* section 4 closed */}

      {/* section 5 start */}
      {/* web view */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        my={"40px"}
        mx={"auto"}
        gap={"84px"}
        display={{ base: "none", md: "grid" }}
      >
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI4} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              3 QNA regular
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹2,500 <br />
              <br />
              (Delivery in 20 business days)
              <br /> Perfect for those who need quick answers to a very
              specific, focused area of life
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/KXuaRf2b", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI5} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              6 QNA regular
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹4,000 <br />
              <br />
              (Delivery in 20 business days)
              <br /> Explore 2-3 key areas of your life with a balanced and
              personalized astrological overview.{" "}
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/e2U8g6jY", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI6} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              10 QNA regular
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹5,500 <br />
              <br />
              (Delivery in 20 business days) <br />A comprehensive look at
              multiple aspects of your life for detailed guidance.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/lrae0WL", "_self");
            }}
          />
        </VStack>
      </SimpleGrid>

      {/* mobile view */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        my={"40px"}
        mx={"auto"}
        gap={"40px"}
        spacing={"20px"}
        display={{ base: "grid", md: "none" }}
        paddingTop={{ base: "40px", md: 0 }}
        paddingBottom={{ base: "40px", md: 0 }}
        bgColor={{ base: `#FDE9CF`, md: "none" }}
        className={mask2Class}
      >
        <VStack gap={"20px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI4} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              3 QNA regular
            </Text>

            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹2,500
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 20 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"28px"}
                textAlign={"center"}
              >
                Perfect for those who need quick answers to a very specific,
                focused area of life
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/KXuaRf2b", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI5} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              6 QNA regular
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹4,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 20 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                Explore 2-3 key areas of your life with a balanced and
                personalized astrological overview.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/e2U8g6jY", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI6} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              10 QNA regular
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹5,500
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 20 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                A comprehensive look at multiple aspects of your life for
                detailed guidance.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/lrae0WL", "_self");
            }}
          />
        </VStack>
      </SimpleGrid>

      <Image
        src={border}
        width={"100vw"}
        display={{ base: "none", md: "flex" }}
      />
      {/* section 5 closed */}

      {/* section 6 and 7 start */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        my={"40px"}
        mx={"auto"}
        gap={{ base: "40px", md: "84px" }}
      >
        <VStack
          gap={"38px"}
          justifyContent={"space-between"}
          mx={{ base: "5", md: "10" }}
        >
          <Flex
            gap={{ base: "20px", md: "27px" }}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI7} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              15 QNA regular
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              ₹7,000
              <br />
              <br /> (Delivery in 20 business days) <br />
              Dive deep into every corner of your life, getting insights that
              cover all areas thoroughly
            </Text>
            <VStack display={{ base: "flex", md: "none" }}>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹7,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 20 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                Dive deep into every corner of your life, getting insights that
                cover all areas thoroughly
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/f40f1nAv", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={{ base: "20px", md: "27px" }}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI8} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Family notes
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={{ bae: "24px", md: "28px" }}
              textAlign={"center"}
            >
              Receive detailed astrological insights for all family members to
              foster harmony and resolve conflicts.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+919601465169", "_blank");
            }}
          />
        </VStack>
        <VStack
          gap={"38px"}
          justifyContent={"space-between"}
          display={{ base: "none", md: "flex" }}
          mx={10}
        >
          <Flex
            gap={{ base: "20px", md: "27px" }}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI9} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              3 QNA urgent
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              ₹5,000
              <br />
              <br /> (Delivery in 2-3 business days)
              <br /> For those who need immediate clarity on one pressing area.
            </Text>
            <VStack display={{ base: "flex", md: "none" }}>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹5,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 2-3 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                For those who need immediate clarity on one pressing area.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/v5482s7", "_self");
            }}
          />
        </VStack>
      </SimpleGrid>

      <Image
        src={border}
        width={"100vw"}
        display={{ base: "none", md: "flex" }}
      />
      {/* webview */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        my={"40px"}
        mx={"auto"}
        gap={"84px"}
        display={{ base: "none", md: "grid" }}
      >
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI10} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              6 QNA urgent
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹8,000
              <br />
              <br /> (Delivery in 2-3 business days)
              <br /> Get fast, personalized advice on a couple of important life
              areas.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/GkF6VScd", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI11} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              10 QNA urgent
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹11,000
              <br />
              <br /> (Delivery in 2-3 business days)
              <br /> A quick yet comprehensive assessment across all major life
              areas.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/D4x9Pm78", "_self");
            }}
          />
        </VStack>
        <VStack gap={"38px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"27px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI12} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "16px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              15 QNA urgent
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"28px"}
              textAlign={"center"}
            >
              ₹14,000 <br />
              <br />
              (Delivery in 2-3 business days)
              <br /> Detailed, fast-tracked insights covering every significant
              part of your life.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/i3y1BEDc", "_self");
            }}
          />
        </VStack>
      </SimpleGrid>

      {/* mobile view */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        mt={"40px"}
        mx={"auto"}
        gap={"40px"}
        spacing={"20px"}
        display={{ base: "grid", md: "none" }}
        paddingTop={{ base: "40px", md: 0 }}
        paddingBottom={{ base: "40px", md: 0 }}
        bgColor={{ base: `#FDE9CF`, md: "none" }}
        className={mask2Class}
      >
        <VStack gap={"20px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI9} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              3 QNA urgent
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹5,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 2-3 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                For those who need immediate clarity on one pressing area.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/v5482s7", "_self");
            }}
          />
        </VStack>
        <VStack gap={"20px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI10} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              6 QNA urgent
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹8,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 2-3 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                Get fast, personalized advice on a couple of important life
                areas.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/GkF6VScd", "_self");
            }}
          />
        </VStack>
        <VStack gap={"20px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI11} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              10 QNA urgent
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"20px"}
                textAlign={"center"}
              >
                ₹11,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"20px"}
                textAlign={"center"}
              >
                (Delivery in 2-3 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"20px"}
                textAlign={"center"}
              >
                A quick yet comprehensive assessment across all major life
                areas.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/D4x9Pm78", "_self");
            }}
          />
        </VStack>
        <VStack gap={"20px"} justifyContent={"space-between"} mx={10}>
          <Flex
            gap={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={NI12} w={"149px"} h={"100px"} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "20px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              15 QNA urgent
            </Text>
            <VStack>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                ₹14,000
              </Text>
              <br />
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                (Delivery in 2-3 business days)
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"24px"}
                textAlign={"center"}
              >
                Detailed, fast-tracked insights covering every significant part
                of your life.
              </Text>
            </VStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Now"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              window.open("https://rzp.io/rzp/i3y1BEDc", "_self");
            }}
          />
        </VStack>
      </SimpleGrid>
      {/* section 6 and 7 closed */}

      {/* section 8 start */}
      {/* web view */}
      <VStack
        bgImage={`url('${Background3}')`}
        className={c2}
        w={"full"}
        h={{ base: "full", md: "960px" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"center"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          mx={"80px"}
          width="50%"
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"70px"}
            maxW={"100%"}
          >
            How Our Written Notes Have Transformed Lives?{" "}
          </Text>
          <Flex gap={"3"} flexDir={"column"}>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"80%"}
              >
                Reduced fear, stress, and uncertainty by directly addressing
                your concerns
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"80%"}
              >
                Gained clarity on their strengths and areas of concern.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"80%"}
              >
                Made informed and wise decisions in their life.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"80%"}
              >
                Recognize favorable aspects of their life and learn to avoid
                pitfalls.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Focused on what truly matters for a happy life.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Navigated life's challenges with confidence.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Connected the dots of life events, with a deeper understanding
                of their karmas and its impact.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Discovered tools to change their destiny by aligning actions
                with their karmic path.
              </Text>
            </HStack>
          </Flex>
        </Flex>
      </VStack>

      {/* mobile view */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        // alignItems={"start"}
        // justifyContent={"start"}

        display={{ base: "flex", md: "none" }}
        paddingBottom={"20px"}
      >
        <Flex
          paddingTop={"20px"}
          bgImage={`url('${Background3}')`}
          w={"100%"}
          h={"400px"}
          backgroundPosition={"right"}
          backgroundSize={"cover"}
          display={{ base: "flex", md: "none" }}
        ></Flex>

        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Flex
            ml={{ base: "0px", md: "100px" }}
            width={{ base: "fit-content", md: "50vh" }}
            flexDir={"column"}
            justifyContent={{ base: "center", md: "start" }}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              textTransform={"capitalize"}
              fontWeight={900}
              lineHeight={"30px"}
              // mx={"20"}
              textAlign={"center"}
            >
              How Our Written Notes Have Transformed Lives?{" "}
            </Text>
            <Flex
              alignItems={"start"}
              justifyContent={"start"}
              gap={8}
              mx={"auto"}
              flexDir={"column"}
              p={2}
            >
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Reduced fear, stress, and uncertainty by directly addressing
                  your concerns.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Gained clarity on their strengths and areas of concern.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Made informed and wise decisions in their life.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Recognize favorable aspects of their life and learn to avoid
                  pitfalls.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Focused on what truly matters for a happy life.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Navigated life's challenges with confidence.
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Connected the dots of life events, with a deeper understanding
                  of their karmas and its impact.{" "}
                </Text>
              </HStack>
              <HStack>
                <CheckIcon fontSize={"20px"} />
                <Text
                  fontFamily={"Jost"}
                  fontSize={"20px"}
                  fontWeight={400}
                  // lineHeight={"20px"}
                  w={"90%"}
                >
                  Discovered tools to change their destiny by aligning actions
                  with their karmic path.{" "}
                </Text>
              </HStack>
              <Button
                variant={"trPrimary"}
                title={"Get Your Written Notes Now!"}
                borderRadius={"10px"}
                padding={"13px 20px"}
                fontSize={"15px"}
                fontWeight={500}
                lineHeight={"22px"}
                height={{ base: "36px", md: "5vh" }}
                mx={"auto"}
                onClick={() => {
                  navigateToSection("/services/notes", "notes-booking");
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </VStack>
      {/* section 8 closed */}

      <Box height="5vh" display={{ base: "flex", md: "none" }} />
      <Testimonials screen={"notes"} />
      {/* <Box height="10vh" /> */}
      <FAQS headText={"FAQS"} type="notes" />
      {/* <Box height="10vh" display={{ base: "flex", md: "none" }} /> */}
      <Footer viewRatings={true} />
    </>
  );
};

export default WrittenNotes;
